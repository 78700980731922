/** **************************** Import Types ****************************** */
import { NOTIFICATION_SUCCESS } from "../types";

const initialState = {
  loading: false,
  storeNotificationResponse: "",
  error: "",
};

export const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_SUCCESS:
      return {
        ...state,
        storeNotificationResponse: action.payload,
      };
    default:
      return state;
  }
};
