/** **************************** Import Types ****************************** */
import { NOTIFICATION_SUCCESS } from "../types";

export const NotificationSuccess = (users) => ({
  type: NOTIFICATION_SUCCESS,
  payload: users,
});

export const GetNotificationDetails = (data) => ({
  type: NOTIFICATION_SUCCESS,
  payload: data,
});
