// ******************************************* Import packages *******************************************
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { useDispatch } from "react-redux";
import GitInfo from "react-git-info/macro";

// ******************************************* Import components *******************************************
import { LoginUser } from "../../redux/actions";
import ConfirmPassword from "../../components/confirmPassword";
import Toast from "../../components/toast/index";
import Subscription from "../../components/subscription";

// ******************************************* Import images *******************************************
import Banner from "../../assets/images/sign-in-main.png";
import Logo from "../../assets/icons/new-main-logo.svg";
import "./sign-in.css";

const Index = () => {
  const dispatch = useDispatch();
  const gitInfo = GitInfo();
  const [isPassword, setIsPassword] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSuccess, setToastSuccess] = useState(false);
  const [subpopup, setSubPopup] = useState(false);

  useEffect(() => {
    const showPopupAfterReload = localStorage.getItem("showPopupAfterReload");
    const userData = localStorage.getItem("loggedUser");
    const userRole = userData ? JSON.parse(userData) : "";
    if (showPopupAfterReload === "true") {
      setIsPassword(userRole?.UserManagement);
      setOpenPopup(true);
      localStorage.removeItem("showPopupAfterReload");
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .required("Email is required")
        .strict()
        .matches(
          /^([a-zA-Z])(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Enter the valid email"
        )
        .email("Enter valid email")
        .lowercase("Email must be lowercase"),
      password: yup.string().required("Password is required"),
      // .min(5, "Minimum 5 characters is required"),
    }),
    onSubmit: async (values) => {
      setLoader(true);
      try {
        const inputData = { ...values };
        await dispatch(
          LoginUser(inputData, setToastSuccess, setToastMessage, setShowToast)
        ).then((res) => {
          if (res?.is_expired) {
            setSubPopup(true);
          }
          if (res?.UserManagement?.is_password_changed === false) {
            localStorage.setItem("showPopupAfterReload", "true");
            // Reload the page
            window.location.reload();
          }
        });
      } finally {
        setLoader(false);
      }
    },
  });

  return (
    <>
      <div
        className="container-fluid p-0"
        style={{ height: "100vh", overflow: "hidden" }}
      >
        <div className="row w-100" style={{ height: "49rem" }}>
          <div className="col-7">
            <div className="banner-section p-5 pt-3 pb-0">
              <div className="banner-section-logo">
                <img src={Logo} width="20%" className="pb-2 pt-3" />
                <p className="mb-4 mt-3">
                  Sign In to explore a world of Organized Leads, Actionable
                  Insights, and Enhanced
                  <br /> Collaboration
                </p>
              </div>
              <div className="text-center">
                <img
                  src={Banner}
                  className="img-fluid"
                  width="650px"
                  // style={{ paddingTop: "5rem" }}
                />
              </div>
            </div>
          </div>
          <div className="col-5 d-flex align-items-center">
            <form
              onSubmit={formik.handleSubmit}
              className="w-100 sign-in-UI-button"
            >
              <div>
                <h2 className="mb-4">Sign In</h2>
                <div>
                  <label className="mb-2 label-tag">Email Address</label>
                  <sup className="sup-symbol">*</sup>
                  <br />
                  <input
                    maxLength={150}
                    name="email"
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="Enter your email"
                    className="input-sign-in mb-1"
                    onKeyPress={(e) => {
                      // Prevent the input of spaces
                      if (e.key === " ") {
                        e.preventDefault();
                      }
                    }}
                    type="text"
                    disabled={formik.isSubmitting}
                  />
                  <br />
                  {formik.errors.email && formik.touched.email ? (
                    <>
                      <span className="error mb-4">{formik.errors.email}</span>
                      <br />
                    </>
                  ) : (
                    <span className="error mb-4" />
                  )}
                  <label className="mb-2 label-tag">Password</label>
                  <sup className="sup-symbol">*</sup>
                  <br />
                  <div className="position-relative">
                    <input
                      maxLength={16}
                      name="password"
                      value={formik.values.password}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder="Enter your password"
                      className="input-sign-in mb-1"
                      type={showPassword ? "text" : "password"}
                      disabled={formik.isSubmitting}
                    />
                    {showPassword ? (
                      <BsEye
                        className="cursor-pointer eye-icon"
                        size={20}
                        onClick={() => setShowPassword(false)}
                      />
                    ) : (
                      <BsEyeSlash
                        className="cursor-pointer eyeSlash-icon"
                        size={20}
                        onClick={() => setShowPassword(true)}
                      />
                    )}
                  </div>
                  {formik.errors.password && formik.touched.password ? (
                    <span className="error p-0 m-0">
                      {formik.errors.password}
                    </span>
                  ) : (
                    <span className="error" />
                  )}
                </div>
                <div className="mt-4">
                  <button
                    className="sign-btn"
                    type="submit"
                    disabled={loader || formik.isSubmitting}
                  >
                    {loader ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Signing In...
                      </>
                    ) : (
                      "Sign In"
                    )}
                  </button>
                </div>
              </div>
            </form>
            <p className="git-tag">
              {/* {gitInfo?.tags?.[0]} */}
              v2.0
            </p>
          </div>
        </div>
      </div>
      <ConfirmPassword
        userDetails={isPassword}
        setOpenPopup={setOpenPopup}
        openPopup={openPopup}
        setToastMessage={setToastMessage}
        setToastSuccess={setToastSuccess}
        setShowToast={setShowToast}
      />
      <Subscription subpopup={subpopup} setSubPopup={setSubPopup} />
      <Toast
        message={toastMessage}
        success={toastSuccess}
        showToast={showToast}
        setShowToast={setShowToast}
      />
    </>
  );
};

export default Index;
