// reducers/darkThemeReducer.js
import { DARK_THEME } from "../types/darkThemeTypes";

const initialState = {
    isToggled: false,
    theme: "light"
};

export const DarkThemeReducer = (state = initialState, action) => {
    switch (action.type) {
        case DARK_THEME:
            return {
                ...state,
                isToggled: action.payload,
                theme: action.payload ? "dark" : "light",
            };
        default:
            return state;
    }
};
