/** **************************** Import Types ****************************** */
import { toast } from "react-toastify";
import {
  LOGIN_USERS_REQUEST,
  LOGIN_USERS_SUCCESS,
  LOGIN_USERS_FAILURE,
} from "../types/loginTypes";
import { postLogin } from "../../api/create";
import "react-toastify/dist/ReactToastify.css";

export const LoginUsersRequest = () => ({
  type: LOGIN_USERS_REQUEST,
});
export const LoginUsersSuccess = (users) => {
  return {
    type: LOGIN_USERS_SUCCESS,
    payload: users,
  };
};

export const LoginUsersFailure = (error) => ({
  type: LOGIN_USERS_FAILURE,
  payload: error,
});

// export const LoginUser = (data) =>
//   async function (dispatch) {
//     dispatch(LoginUsersRequest());
//     await postLogin(data)
//       .then((res) => {
//         if (res.status) {
//           // const loggedUser = res.result;
//           // const accessToken = res.result?.token?.access?.token;
//           // const accessExpiry = res.result?.token?.access?.expires;
//           // const RefreshToken = res.result?.token?.refresh?.token;
//           // const refreshExpiry = res.result?.token?.refresh?.expires;
//           // localStorage.setItem("accessExpiry", accessExpiry);
//           // localStorage.setItem("refreshToken", RefreshToken);
//           // localStorage.setItem("refreshExpiry", refreshExpiry);
//           // localStorage.setItem("token", accessToken);
//           // localStorage.setItem("loggedUser", JSON.stringify(loggedUser));
//           dispatch(LoginUsersSuccess({ responseStatus: "success" }));
//           toast.success("Logged in Successfully", {
//             transition: Zoom,
//           });
//           setTimeout(() => {
//             window.location.href = "/home/dashboard";
//           }, 3000);
//         } else {
//           dispatch(LoginUsersFailure(res.error));
//           toast.error(res.error, {
//             transition: Zoom,
//           });
//         }
//       })
//       .catch((err) => console.log("Catch Error:", err));
//   };

export const LoginUser = (
  data,
  setToastSuccess,
  setToastMessage,
  setShowToast
) =>
  async function (dispatch) {
    dispatch(LoginUsersRequest());
    return postLogin(data).then((res) => {
      if (!res.detail && !res?.error) {
        const loggedUser = res?.records;
        const accessToken = res?.records?.UserManagement?.access_token;
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("loggedUser", JSON.stringify(loggedUser));
        dispatch(LoginUsersSuccess({ responseStatus: "success" }));

        if (
          loggedUser?.UserManagement?.is_password_changed &&
          loggedUser?.is_expired === false
        ) {
          // toast.success(res?.message ? res?.message : "Logged-in Successful");
          setToastMessage(res?.message ? res?.message : "Logged-in Successful");
          setToastSuccess(true);
          setShowToast(true);
          setTimeout(() => {
            window.location.href = "/dashboard";
          }, 3000);
        }
        return res.records;
      }
      dispatch(LoginUsersFailure(res.error));
      // toast.error(
      //   res.detail?.message ? res?.detail?.message : "Invalid Credentials"
      // );
      setToastMessage(
        res.detail?.message ? res?.detail?.message : "Invalid Credentials"
      );
      setToastSuccess(false);
      setShowToast(true);
    });
  };
