// ******************************************* Import packages *******************************************
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import SubImage from "../../assets/icons/empty-wallet-remove.svg";
import Cancel from "../../assets/icons/pop-up-edit.svg";
import CancelDark from "../../assets/icons/cancel-dark.svg";

const Subscription = ({ setSubPopup, subpopup }) => {
  return (
    <div>
      {" "}
      <Modal
        show={subpopup}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body onClick={(e) => e.stopPropagation()}>
          <div>
            <div>
              <div className="d-flex justify-content-center position-relative">
                <img
                  style={{
                    borderRadius: "8px",
                    backgroundColor: "#F2F6FF",
                    padding: "10px",
                  }}
                  src={SubImage}
                  alt="SubImage"
                />
                <img
                  src={Cancel}
                  onClick={() => {
                    setSubPopup(false);
                  }}
                  width="38px"
                  height="38px"
                  style={{
                    cursor: "pointer",
                    zIndex: "10px",
                    // background: isToggled ? "none" : "#FFFFFF",
                    position: "absolute",
                    right: 0,
                    // paddingTop: "10px",
                  }}
                  //   className={`${isToggled ? "cancel-button" : ""}`}
                  alt="CancelDark"
                />
              </div>
              <h3
                className="text-center"
                style={{ fontSize: "24px", fontWeight: "600" }}
              >
                Subscription
              </h3>
              <p
                className="admin-para text-center"
                style={{ color: "#778399", fontSize: "15px" }}
              >
                Your Subscription has ended.
                <br />
                Please contact admin to regain access.
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Subscription;
