// ******************************************* Import packages *******************************************
import React, { useRef } from "react";
import "./input.css";

const Index = (props) => {
  const {
    className,
    placeholder,
    width,
    disabled,
    value,
    onChange,
    onKeyDown,
    type,
    name,
    error,
    onBlur,
    min,
    position,
    max,
    styles,
    onKeyPress,
    autoComplete,
    isToggled,
    onFocus,
  } = props;

  const inputRef = useRef(null);

  return (
    <div className="w-100">
      <input
        ref={inputRef}
        style={{
          border: error
            ? "1px solid red"
            : isToggled
            ? "1px solid #333"
            : "1px solid #E2E3E5",
          cursor: disabled ? "not-allowed" : "auto",
          position: position ? position : null,
          ...styles,
        }}
        className={` placeholderss ${
          className ? className : "custom-input mb-2"
        } ${isToggled ? "custom-input-dark" : ""} `}
        value={value}
        min={min}
        maxLength={max}
        autoComplete={autoComplete}
        placeholder={placeholder}
        width={width}
        disabled={disabled}
        onChange={onChange ? (e) => onChange(e) : null}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        type={type}
        name={name}
        onKeyPress={(e) => {
          if (onKeyPress) onKeyPress(e);
          if (e.key === " " && inputRef.current.value.length === 0)
            e.preventDefault();
        }}
        onClick={(e) => {
          if (type === "date" && inputRef.current?.showPicker) {
            inputRef.current.showPicker();
          }
          if (type === "time" && inputRef.current?.showPicker) {
            inputRef.current.showPicker();
          }
        }}
      />
    </div>
  );
};

export default Index;
