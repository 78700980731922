// ******************************************* Import packages *******************************************
import React from "react";
import "./error.css";

const Index = (props) => {
  const { error } = props;
  return (
    <div>
      <p className="error-component">{error}</p>
    </div>
  );
};

export default Index;
