/** ****************************** Import libs *********************************** */
import { postDataApi, postDataApiByID } from "./actions";
import { URL_CONSTANTS } from "./urls";

export const postLogin = (params) => postDataApi(URL_CONSTANTS.login, params);
export const postTeam = (params) => postDataApi(URL_CONSTANTS.team, params);
export const postLead = (params) =>
  postDataApi(URL_CONSTANTS.createLead, params);
export const postLeadOrg = (params) =>
  postDataApi(URL_CONSTANTS.createLeadOrg, params);
export const postClientOrg = (params) =>
  postDataApi(URL_CONSTANTS.createClientOrg, params);
export const postOrg = (params) => postDataApi(URL_CONSTANTS.createOrg, params);
export const postMeeting = (params) =>
  postDataApi(URL_CONSTANTS.createMeeting, params);
export const postMail = (params) =>
  postDataApi(URL_CONSTANTS.mailCreate, params);
export const postDeal = (params) =>
  postDataApi(URL_CONSTANTS.createDeal, params);
export const postImportDetail = (params) =>
  postDataApi(URL_CONSTANTS.postImport, params);
export const postRole = (params) =>
  postDataApi(URL_CONSTANTS.rolesPost, params);

export const postMultiCold = (params) =>
  postDataApi(URL_CONSTANTS.createMultiPleColdReach, params);
export const updateClosedReview = (params) =>
  postDataApi(URL_CONSTANTS.updateReview, params);
export const uploadFile = (params) =>
  postDataApi(URL_CONSTANTS.uploadFile, params);
export const postContacts = (params) =>
  postDataApi(URL_CONSTANTS.contacts, params);
export const postMultiAssign = (params) =>
  postDataApi(URL_CONSTANTS.createMultiAssign, params);
export const postSignleAssign = (params) =>
  postDataApi(URL_CONSTANTS.createSingleAssign, params);

export const postToken = (params) =>
  postDataApi(URL_CONSTANTS.addToken, params);
export const updateColdReachActivity = (params) =>
  postDataApi(URL_CONSTANTS.putColdLeadActivity, params);
export const postColdReachAcivity = (params) =>
  postDataApi(URL_CONSTANTS.coldReachGetActivity, params);
export const postMultiReAssign = (params) =>
  postDataApi(URL_CONSTANTS.createMultiReAssign, params);
export const postSingleReAssign = (params) =>
  postDataApi(URL_CONSTANTS.createSingleReAssign, params);
export const createLeadandOrg = (params) =>
  postDataApi(URL_CONSTANTS.createContactLead, params);
export const batchDeleteDropped = (params) =>
  postDataApi(URL_CONSTANTS.droppedBatchDel, params);

export const MovetoProspects=(params)=>
  postDataApi(URL_CONSTANTS.ProspectsMove,params);

export const BatchProspectMove = (params) =>
  postDataApi(URL_CONSTANTS.BatchProspectsMove, params);

export const BatchDeleteProspects = (params) =>
  postDataApi(URL_CONSTANTS.BatchProspectDelete, params);

export const deleteMultiDeleteLeads = (dataIds) =>
  postDataApi(URL_CONSTANTS.deleteMultiLeads, dataIds);

export const deleteMultiDeleteClients = (dataIds) =>
  postDataApi(URL_CONSTANTS.deleteMultiClients, dataIds);

export const BatchDeleteOrganiZation = (params) =>
  postDataApi(URL_CONSTANTS.batchDeleteOrg, params);


export const PostProspectsMultiAssign = (params) =>
  postDataApi(URL_CONSTANTS.ProspectsMultiAssign, params);

export const PostProspectsSingleAssign = (params) =>
  postDataApi(URL_CONSTANTS.ProspectsSingleAssign, params);

export const postLeadsSingleReAssign = (params) =>
  postDataApi(URL_CONSTANTS.createSignleLeadAssign, params);
export const postLeadsSMultiReAssign = (params) =>
  postDataApi(URL_CONSTANTS.createMultiLeadReAssign, params);

export const MovetoSingleLeads=(params)=>
  postDataApi(URL_CONSTANTS.LeadsSignleMove,params);

export const MovetoMultiLeads=(params)=>
  postDataApi(URL_CONSTANTS.LeadsMultiMove,params);

export const RemoveSheetFromServer=(params)=>
  postDataApi(URL_CONSTANTS.deleteStaticDataImport,params);
