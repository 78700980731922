/* eslint-disable */
/** ********************************* Import URL ************************************* */
import { toast } from "react-toastify";
import { hostConfig } from "../config"; // env
import { URL_CONSTANTS } from "./urls";
import "../App.css";

/** ****************************** Response Handler *********************************** */

const token = localStorage.getItem("accessToken");
const reLogin = () => {
  if (!localStorage.getItem("loggedUser")) {
    return { error: "Invalid emaiId or password" };
  } else if (localStorage.getItem("accessExpiry")) {
    const accessExipryTime = new Date(localStorage.getItem("accessExipry"));
    const currentDate = new Date();
    if (accessExipryTime < currentDate) {
      const refreshToken = localStorage.getItem("refreshToken");
      const params = { refreshToken: refreshToken };
      return fetch(`${hostConfig.TEST_URL}${URL_CONSTANTS.refreshToken}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(params),
      })
        .then((response) => {
          return response;
        })
        .then(async (resp) => {
          const res = await resp.json();
          const accessToken = res.access.token;
          const refreshToken = res.refresh.token;
          const accessExpiry = res.access.expires;
          const refreshExpiry = res.refresh.expires;
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("refreshToken", refreshToken);
          localStorage.setItem("accessExpiry", accessExpiry);
          localStorage.setItem("refreshExpiry", refreshExpiry);
          window.location.reload();
        })
        .catch((err) => {
          errorHandler(err);
        });
    }
    a;
  } else {
    localStorage.clear();
    window.location.href = "/";
  }
};
const logout = (res) => {
  res?.json().then((data) => {
    toast.error(
      data?.detail?.message ||
        data?.detail?.session_message ||
        (typeof data?.detail === "string" && data?.detail),
      {
        position: "bottom-center",
        hideProgressBar: true,
        // className: "custom-toast",
      }
    );
    setTimeout(() => {
      window.location.href = "/";
    }, 2000);
  });
};
const responseStatusHandler = (response) => {
  switch (response.status) {
    case 400:
      return response;
    case 422:
      return response;
    case 401:
    // return reLogin();
    case 402:
      return { error: "Payment Required" };
    case 403:
      logout(response);
      return { error: "Forbidden" };
    case 404:
      return { error: "Not Found" };
    case 405:
      return { error: "Method Not Allowed" };
    case 406:
      return { error: "Not Acceptable" };
    case 408:
      return { error: "Request Timeout" };
    case 409:
      return { error: "Request Already Exist" };
    case 410:
      return { error: "permanently deleted from server" };
    case 500:
      return { error: "Internal Server Error" };
    case 501:
      return { error: "Not Implemented" };
    case 502:
      return { error: "Bad Gateway" };
    case 503:
      return { error: "Service Unavailable" };
    case 504:
      return { error: " Gateway Timeout" };
    case 511:
      return { error: " Network Authentication Required" };
    case 200:
    case 201:
      return response;
    default:
      return false;
  }
};

/** ****************************** Error Handler *********************************** */
const errorHandler = (error) => error;

/** ****************************** Create Api *********************************** */
export const postDataApi = (requestUrl, params, service) => {
  return fetch(
    `${
      service === "JSONSERVER" ? hostConfig.JSON_SERVER_URL : hostConfig.API_URL
    }${requestUrl}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(params),
    }
  )
    .then((response) => {
      return responseStatusHandler(response);
    })
    .then((result) =>
      result.status === 200 ||
      result.status === 201 ||
      result.status === 400 ||
      result.status === 422
        ? result.json()
        : result
    )
    .catch((err) => {
      errorHandler(err);
    });
};

export const postDataApiByID = (requestUrl, params, paramId) => {
  let getParams = "?";
  if (params?.type) {
    getParams += `&type=${params.type}`;
  }
  return fetch(`${hostConfig.API_URL}${requestUrl}/${paramId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
    .then((response) => responseStatusHandler(response))
    .then((result) =>
      result.status === 200 || result.status === 201 || result.status === 400
        ? result.json()
        : result
    )
    .catch((error) => {
      errorHandler(error);
    });
};

/** ****************************** View with query Api *********************************** */
export const getListByApi = (requestUrl, params, service) => {

  let getParams = "?";
  if (
    params &&
    params.limit &&
    params.limit !== null &&
    params.limit !== undefined
  ) {
    getParams += `limit=${params.limit}`;
  }

  if (
    params &&
    params.is_reassigned &&
    params.is_reassigned !== null &&
    params.is_reassigned !== undefined
  ) {
    getParams += `&is_reassigned=${params.is_reassigned}`;
  }
  if (
    params &&
    params.designation &&
    params.designation !== null &&
    params.designation !== undefined
  ) {
    getParams += `&designation=${params.designation}`;
  }
  if (
    params &&
    params.is_deal_going &&
    params.is_deal_going !== null &&
    params.is_deal_going !== undefined
  ) {
    getParams += `&is_deal_going=${params.is_deal_going}`;
  }
  if (
    params &&
    params.assigned_by_filter &&
    params.assigned_by_filter !== null &&
    params.assigned_by_filter !== undefined
  ) {
    getParams += `&assigned_by_filter=${params.assigned_by_filter}`;
  }

  if (
    params &&
    params.isDue !== "" &&
    params.isDue !== null &&
    params.isDue !== undefined
  ) {
    getParams += `&isDue=${params.isDue}`;
  }

  if (
    params &&
    params.start_year !== "" &&
    params.start_year !== null &&
    params.start_year !== undefined
  ) {
    getParams += `&start_year=${params.start_year}`;
  }

  if (
    params &&
    params.start_date !== "" &&
    params.start_date !== null &&
    params.start_date !== undefined
  ) {
    getParams += `&start_date=${params.start_date}`;
  }

  if (
    params &&
    params.end_date !== "" &&
    params.end_date !== null &&
    params.end_date !== undefined
  ) {
    getParams += `&end_date=${params.end_date}`;
  }
  if (
    params &&
    params.report_type !== "" &&
    params.report_type !== null &&
    params.report_type !== undefined
  ) {
    getParams += `&report_type=${params.report_type}`;
  }
  if (
    params &&
    params.users !== "" &&
    params.users !== null &&
    params.users !== undefined
  ) {
    getParams += `&users=${params.users}`;
  }
  if (
    params &&
    params.last_cold_reach &&
    params.last_cold_reach !== null &&
    params.last_cold_reach !== undefined
  ) {
    getParams += `&last_cold_reach=${params.last_cold_reach}`;
  }

  // if (
  //   params &&
  //   params.min_rating !== null &&
  //   params.min_rating !== "" &&
  //   params.min_rating !== undefined
  // ) {
  //   getParams += `&min_rating=${params.min_rating}`;
  // }

  // if (
  //   params &&
  //   params.max_rating !== null &&
  //   params.max_rating !== "" &&
  //   params.max_rating !== undefined
  // ) {
  //   getParams += `&max_rating=${params.max_rating}`;
  // }
  if (
    params &&
    params.source !== null &&
    params.source !== "" &&
    params.source !== undefined
  ) {
    getParams += `&source=${params.source}`;
  }
  if (
    params &&
    params.unassigned_filter &&
    params.unassigned_filter !== null &&
    params.unassigned_filter !== undefined
  ) {
    getParams += `&unassigned_filter=${params.unassigned_filter}`;
  }
  if (
    params &&
    params.upcoming_meeting_date &&
    params.upcoming_meeting_date !== null &&
    params.upcoming_meeting_date !== undefined
  ) {
    getParams += `&upcoming_meeting_date=${params.upcoming_meeting_date}`;
  }
  if (
    params &&
    params.report_type_lead &&
    params.report_type_lead !== null &&
    params.report_type_lead !== undefined
  ) {
    getParams += `&report_type_lead=${params.report_type_lead}`;
  }
  if (
    params &&
    params.report_type_deal &&
    params.report_type_deal !== null &&
    params.report_type_deal !== undefined
  ) {
    getParams += `&report_type_deal=${params.report_type_deal}`;
  }
  if (
    params &&
    params.roleId &&
    params.roleId !== null &&
    params.roleId !== undefined
  ) {
    getParams += `&roleId=${params.roleId}`;
  }
  if (
    params &&
    params.related_to &&
    params.related_to !== null &&
    params.related_to !== undefined
  ) {
    getParams += `&related_to=${params.related_to}`;
  }
  if (
    params &&
    params.year !== null &&
    params.year !== "" &&
    params.year !== undefined
  ) {
    getParams += `&year=${params.year}`;
  }

  if (
    params &&
    params.role_id &&
    params.role_id !== null &&
    params.role_id !== undefined
  ) {
    getParams += `&role_id=${params.role_id}`;
  }
  if (
    params &&
    params.report_type_cold_reach &&
    params.report_type_cold_reach !== null &&
    params.report_type_cold_reach !== undefined
  ) {
    getParams += `&report_type_cold_reach=${params.report_type_cold_reach}`;
  }

  if (
    params &&
    params.comparison_data_lead &&
    params.comparison_data_lead !== null &&
    params.comparison_data_lead !== undefined
  ) {
    getParams += `&comparison_data_lead=${params.comparison_data_lead}`;
  }
  if (
    params &&
    params.comparison_data_deal &&
    params.comparison_data_deal !== null &&
    params.comparison_data_deal !== undefined
  ) {
    getParams += `&comparison_data_deal=${params.comparison_data_deal}`;
  }
  if (
    params &&
    params.comparison_data_cold_reach &&
    params.comparison_data_cold_reach !== null &&
    params.comparison_data_cold_reach !== undefined
  ) {
    getParams += `&comparison_data_cold_reach=${params.comparison_data_cold_reach}`;
  }
  if (
    params &&
    params.cold_reach_status &&
    params.cold_reach_status !== null &&
    params.cold_reach_status !== undefined
  ) {
    getParams += `&cold_reach_status=${params.cold_reach_status}`;
  }
  if (
    params &&
    params.lead_year &&
    params.lead_year !== null &&
    params.lead_year !== undefined
  ) {
    getParams += `&lead_year=${params.lead_year}`;
  }
  if (
    params &&
    params.sub_status_filter &&
    params.sub_status_filter !== null &&
    params.sub_status_filter !== undefined
  ) {
    getParams += `&sub_status_filter=${params.sub_status_filter}`;
  }
  if (
    params &&
    params.deal_year &&
    params.deal_year !== null &&
    params.deal_year !== undefined
  ) {
    getParams += `&deal_year=${params.deal_year}`;
  }
  if (
    params &&
    params.cold_reach_year &&
    params.cold_reach_year !== null &&
    params.cold_reach_year !== undefined
  ) {
    getParams += `&cold_reach_year=${params.cold_reach_year}`;
  }
  if (
    params &&
    params.user_id &&
    params.user_id !== null &&
    params.user_id !== undefined
  ) {
    getParams += `&user_id=${params.user_id}`;
  }
  if (
    params &&
    params.assigned_to_filter &&
    params.assigned_to_filter !== null &&
    params.assigned_to_filter !== undefined
  ) {
    getParams += `&assigned_to_filter=${params.assigned_to_filter}`;
  }
  if (
    params &&
    params.month &&
    params.month !== null &&
    params.month !== undefined
  ) {
    getParams += `&month=${params.month}`;
  }
  if (
    params &&
    params.tab_type &&
    params.tab_type !== null &&
    params.tab_type !== undefined
  ) {
    getParams += `&tab_type=${params.tab_type}`;
  }
  if (
    params &&
    params.activity_main_status &&
    params.activity_main_status !== null &&
    params.activity_main_status !== undefined
  ) {
    getParams += `&activity_main_status=${params.activity_main_status}`;
  }

  if (
    params &&
    params.timezone_filter &&
    params.timezone_filter !== null &&
    params.timezone_filter !== undefined
  ) {
    getParams += `&timezone_filter=${params.timezone_filter}`;
  }

  if (
    params &&
    params.main_status_filter &&
    params.main_status_filter !== null &&
    params.main_status_filter !== undefined
  ) {
    getParams += `&main_status_filter=${params.main_status_filter}`;
  }
  if (
    params &&
    params.cold_reach_day_filter &&
    params.cold_reach_day_filter !== null &&
    params.cold_reach_day_filter !== undefined
  ) {
    getParams += `&cold_reach_day_filter=${params.cold_reach_day_filter}`;
  }
  if (
    params &&
    params.cold_reach_type &&
    params.cold_reach_type !== null &&
    params.cold_reach_type !== undefined
  ) {
    getParams += `&cold_reach_type=${params.cold_reach_type}`;
  }
  if (
    params &&
    params.data_source &&
    params.data_source !== null &&
    params.data_source !== undefined
  ) {
    getParams += `&data_source=${params.data_source}`;
  }
  if (
    params &&
    params.lead_kind &&
    params.lead_kind !== null &&
    params.lead_kind !== undefined
  ) {
    getParams += `&lead_kind=${params.lead_kind}`;
  }
  if (
    params &&
    params.deal_category_id &&
    params.deal_category_id !== null &&
    params.deal_category_id !== undefined
  ) {
    getParams += `&deal_category_id=${params.deal_category_id}`;
  }
  if (
    params &&
    params.next_call_on_date &&
    params.next_call_on_date !== null &&
    params.next_call_on_date !== undefined
  ) {
    getParams += `&next_call_on_date=${params.next_call_on_date}`;
  }
  if (
    params &&
    params.deal_ticket_filter &&
    params.deal_ticket_filter !== null &&
    params.deal_ticket_filter !== undefined
  ) {
    getParams += `&deal_ticket_filter=${params.deal_ticket_filter}`;
  }
  if (
    params &&
    params.is_requirement !== "" &&
    params.is_requirement !== null &&
    params.is_requirement !== undefined
  ) {
    getParams += `&is_requirement=${params.is_requirement}`;
  }

  if (params && params.assignTo) {
    getParams += `&assignTo=${params.assignTo}`;
  }
  if (params && params.taskType) {
    getParams += `&taskType=${params.taskType}`;
  }
  if (params?.type) {
    getParams += `&type=${params.type}`;
  }
  if (params?.status) {
    getParams += `&status=${params.status}`;
  }
  if (params && params.workLocation) {
    getParams += `&workLocation=${params.workLocation}`;
  }
  if (params && params.candidateId) {
    getParams += `candidateId=${params.candidateId}`;
  }
  if (params && params.category) {
    getParams += `&category=${params.category}`;
  }
  if (params && params.details) {
    getParams += `&details=${params.details}`;
  }
  if (params && params.max_revenue) {
    getParams += `&max_revenue=${params.max_revenue}`;
  }
  if (params && params.min_revenue) {
    getParams += `&min_revenue=${params.min_revenue}`;
  }
  if (params && params.min_revenue) {
    getParams += `&min_revenue=${params.min_revenue}`;
  }
  if (params && params.static_type) {
    getParams += `&static_type=${params.static_type}`;
  }
  if (
    params &&
    params.revenue &&
    params.revenue !== null &&
    params.revenue !== undefined
  ) {
    getParams += `&revenue=${params.revenue}`;
  }
  if (
    params &&
    params.active_user_filter !== null &&
    params.active_user_filter !== undefined
  ) {
    getParams += `&active_user_filter=${params.active_user_filter}`;
  }
  if (
    params &&
    params.assign_to &&
    params.assign_to !== null &&
    params.assign_to !== undefined
  ) {
    getParams += `&assign_to=${params.assign_to}`;
  }
  if (
    params &&
    params.state &&
    params.state !== null &&
    params.state !== undefined
  ) {
    getParams += `&state=${params.state}`;
  }
  if (
    params &&
    params.assign_to_filter &&
    params.assign_to_filter !== null &&
    params.assign_to_filter !== undefined
  ) {
    getParams += `&assign_to_filter=${params.assign_to_filter}`;
  }
  if (
    params &&
    params.status_filter &&
    params.status_filter !== null &&
    params.status_filter !== undefined
  ) {
    getParams += `&status_filter=${params.status_filter}`;
  }

  if (
    params &&
    params.lead_source &&
    params.lead_source !== null &&
    params.lead_source !== undefined
  ) {
    getParams += `&lead_source=${params.lead_source}`;
  }
  if (
    params &&
    params.contact_source &&
    params.contact_source !== null &&
    params.contact_source !== undefined
  ) {
    getParams += `&contact_source=${params.contact_source}`;
  }

  if (
    params &&
    params.Status &&
    params.Status !== null &&
    params.Status !== undefined
  ) {
    getParams += `&Status=${params.Status}`;
  }

  if (
    params &&
    params.premium &&
    params.premium !== null &&
    params.premium !== undefined
  ) {
    getParams += `&premium=${params.premium}`;
  }

  if (
    params &&
    params.client_from &&
    params.client_from !== null &&
    params.client_from !== undefined
  ) {
    getParams += `&client_from=${params.client_from}`;
  }

  if (
    params &&
    params.client_source &&
    params.client_source !== null &&
    params.client_source !== undefined
  ) {
    getParams += `&client_source=${params.client_source}`;
  }

  if (
    params &&
    params.client_kind &&
    params.client_kind !== null &&
    params.client_kind !== undefined
  ) {
    getParams += `&client_kind=${params.client_kind}`;
  }
  if (
    params &&
    params.lead_score &&
    params.lead_score !== null &&
    params.lead_score !== undefined
  ) {
    getParams += `&lead_score=${params.lead_score}`;
  }
  if (
    params &&
    params.country &&
    params.country !== null &&
    params.country !== undefined
  ) {
    getParams += `&country=${params.country}`;
  }
  if (
    params &&
    params.organization &&
    params.organization !== null &&
    params.organization !== undefined
  ) {
    getParams += `&organization=${params.organization}`;
  }
  if (
    params &&
    params.job_title &&
    params.job_title !== null &&
    params.job_title !== undefined
  ) {
    getParams += `&job_title=${params.job_title}`;
  }
  if (
    params &&
    params.industry &&
    params.industry !== null &&
    params.industry !== undefined
  ) {
    getParams += `&industry=${params.industry}`;
  }
  if (
    params &&
    params.assign_members &&
    params.assign_members !== null &&
    params.assign_members !== undefined
  ) {
    getParams += `&assign_members=${params.assign_members}`;
  }
  if (
    params &&
    params.leads &&
    params.leads !== null &&
    params.leads !== undefined
  ) {
    getParams += `&leads=${params.leads}`;
  }
  if (
    params &&
    params.priority &&
    params.priority !== null &&
    params.priority !== undefined
  ) {
    getParams += `&priority=${params.priority}`;
  }
  if (
    params &&
    params.deal_kind_filter &&
    params.deal_kind_filter !== null &&
    params.deal_kind_filter !== undefined
  ) {
    getParams += `&deal_kind_filter=${params.deal_kind_filter}`;
  }
  if (
    params &&
    params.is_won &&
    params.is_won !== null &&
    params.is_won !== undefined
  ) {
    getParams += `&is_won=${params.is_won}`;
  }
  if (
    params &&
    params.deal_service_type &&
    params.deal_service_type !== null &&
    params.deal_service_type !== undefined
  ) {
    getParams += `&deal_service_type=${params.deal_service_type}`;
  }
  if (
    params &&
    params.export_option &&
    params.export_option !== null &&
    params.export_option !== undefined
  ) {
    getParams += `&export_option=${params.export_option}`;
  }
  if (
    params &&
    params.from_date &&
    params.from_date !== null &&
    params.from_date !== undefined
  ) {
    getParams += `&from_date=${params.from_date}`;
  }
  if (
    params &&
    params.to_date &&
    params.to_date !== null &&
    params.to_date !== undefined
  ) {
    getParams += `&to_date=${params.to_date}`;
  }
  if (params && params.category_path) {
    getParams += `&category_path=${params.category_path}`;
  }
  if (
    params &&
    params.company_size &&
    params.company_size !== null &&
    params.company_size !== undefined
  ) {
    getParams += `&company_size=${params.company_size}`;
  }
  if (
    params &&
    params.is_invest &&
    params.is_invest !== null &&
    params.is_invest !== undefined
  ) {
    getParams += `&is_invest=${params.is_invest}`;
  }
  if (
    params &&
    params.contact_type &&
    params.contact_type !== null &&
    params.contact_type !== undefined
  ) {
    getParams += `&contact_type=${params.contact_type}`;
  }

  if (
    params &&
    params.is_requirement_lead !== "" &&
    params.is_requirement_lead !== null &&
    params.is_requirement_lead !== undefined
  ) {
    getParams += `&is_requirement=${params.is_requirement_lead}`;
  }

  if (
    params &&
    params.verified_filter &&
    params.verified_filter !== null &&
    params.verified_filter !== undefined
  ) {
    getParams += `&verified_filter=${params.verified_filter}`;
  }
  if (
    params &&
    params.is_premium &&
    params.is_premium !== null &&
    params.is_premium !== undefined
  ) {
    getParams += `&is_premium=${params.is_premium}`;
  }
  if (
    params &&
    params.lead_status &&
    params.lead_status !== null &&
    params.lead_status !== undefined
  ) {
    getParams += `&lead_status=${params.lead_status}`;
  }

  if (params && params.category_type) {
    getParams += `&category_type=${params.category_type}`;
  }
  if (params && params.price) {
    getParams += `&price=${params.price}`;
  }
  if (params && params.page) {
    getParams += `&page=${params.page}`;
  }
  if (
    params &&
    params.duration_filter &&
    params.duration_filter !== null &&
    params.duration_filter !== undefined
  ) {
    getParams += `&duration_filter=${params.duration_filter}`;
  }

  if (params && params.response_type) {
    getParams += `&response_type=${params.response_type}`;
  }

  if (
    params &&
    params.token &&
    params.token !== null &&
    params.token !== undefined
  ) {
    getParams += `token=${params.token}`;
  }

  if (
    params &&
    params.currentPage &&
    params.currentPage !== null &&
    params.currentPage !== undefined
  ) {
    getParams += `&page=${params.currentPage}`;
  }

  if (
    params &&
    params.organizationName !== null &&
    params.organizationName !== undefined
  ) {
    getParams += `&organizationName=${params.organizationName}`;
  }

  if (
    params &&
    params.sortBy &&
    params.sortBy !== null &&
    params.sortBy !== undefined
  ) {
    getParams += `&sortBy=${params.sortBy}`;
  }
  if (
    params &&
    params.category_id &&
    params.category_id !== null &&
    params.category_id !== undefined
  ) {
    getParams += `&category_id=${params.category_id}`;
  }
  if (params && params._id && params._id !== null && params._id !== undefined) {
    getParams += `&_id=${params._id}`;
  }

  if (
    params &&
    params.order_id &&
    params.order_id !== null &&
    params.order_id !== undefined
  ) {
    getParams += `&order_id=${params.order_id}`;
  }
  if (
    params &&
    params.product_price &&
    params.product_price !== null &&
    params.product_price !== undefined
  ) {
    getParams += `&product_price=${params.product_price}`;
  }
  if (
    params &&
    params.search &&
    params.search !== null &&
    params.search !== undefined
  ) {
    getParams += `&search=${params.search}`;
  }
  if (
    params &&
    params.isActive !== null &&
    params.isActive !== "" &&
    params.isActive !== undefined
  ) {
    getParams += `&isActive=${params.isActive}`;
  }

  if (params && params.role !== null && params.role !== undefined) {
    getParams += `&role=${params.role}`;
  }

  if (
    params &&
    params.action !== null &&
    params.action !== "" &&
    params.action !== undefined
  ) {
    getParams += `&action=${params.action}`;
  }
  if (
    params &&
    params.lastkey !== null &&
    params.lastkey !== "" &&
    params.lastkey !== undefined
  ) {
    getParams += `&lastkey=${params.lastkey}`;
  }

  if (
    params &&
    params.orderStatus !== null &&
    params.orderStatus !== "" &&
    params.orderStatus !== undefined
  ) {
    getParams += `&orderStatus=${params.orderStatus}`;
  }
  if (
    params &&
    params.productType !== null &&
    params.productType !== "" &&
    params.productType !== undefined
  ) {
    getParams += `&productType=${params.productType}`;
  }
  if (
    params &&
    params.order_status !== null &&
    params.order_status !== "" &&
    params.order_status !== undefined
  ) {
    getParams += `&order_status=${params.order_status}`;
  }

  if (
    params &&
    params.shipped_status !== null &&
    params.shipped_status !== "" &&
    params.shipped_status !== undefined
  ) {
    getParams += `&shipped_status=${params.shipped_status}`;
  }

  if (
    params &&
    params.outForDelivery_status !== null &&
    params.outForDelivery_status !== "" &&
    params.outForDelivery_status !== undefined
  ) {
    getParams += `&outForDelivery_status=${params.outForDelivery_status}`;
  }

  if (
    params &&
    params.delivered_status !== null &&
    params.delivered_status !== "" &&
    params.delivered_status !== undefined
  ) {
    getParams += `&delivered_status=${params.delivered_status}`;
  }

  if (
    params &&
    params.sort !== null &&
    params.sort !== "" &&
    params.sort !== undefined
  ) {
    getParams += `&sort=${params.sort}`;
  }
  if (
    params &&
    params.min_price !== null &&
    params.min_price !== "" &&
    params.min_price !== undefined
  ) {
    getParams += `&min_price=${params.min_price}`;
  }

  if (
    params &&
    params.max_price !== null &&
    params.max_price !== "" &&
    params.max_price !== undefined
  ) {
    getParams += `&max_price=${params.max_price}`;
  }
  if (
    params &&
    params.min_rating !== null &&
    params.min_rating !== "" &&
    params.min_rating !== undefined
  ) {
    getParams += `&min_rating=${params.min_rating}`;
  }

  if (
    params &&
    params.max_rating !== null &&
    params.max_rating !== "" &&
    params.max_rating !== undefined
  ) {
    getParams += `&max_rating=${params.max_rating}`;
  }

  // if (
  //   params &&
  //   params.category_path !== null &&
  //   params.category_path !== "" &&
  //   params.category_path !== undefined
  // ) {
  //   getParams += `&category_path=${params.category_path}`;
  // }
  if (
    params &&
    params.latest !== null &&
    params.latest !== "" &&
    params.latest !== undefined
  ) {
    getParams += `&latest=${params.latest}`;
  }
  if (
    params &&
    params.alphabetsort !== null &&
    params.alphabetsort !== "" &&
    params.alphabetsort !== undefined
  ) {
    getParams += `&alphabetsort=${params.alphabetsort}`;
  }
  if (
    params &&
    params.quantity_sort !== null &&
    params.quantity_sort !== "" &&
    params.quantity_sort !== undefined
  ) {
    getParams += `&quantity_sort=${params.quantity_sort}`;
  }
  if (
    params &&
    params.searchvalue !== null &&
    params.searchvalue !== "" &&
    params.searchvalue !== undefined
  ) {
    getParams += `&searchvalue=${params.searchvalue}`;
  }
  if (
    params &&
    params.search_value !== null &&
    params.search_value !== "" &&
    params.search_value !== undefined
  ) {
    getParams += `&search_value=${params.search_value}`;
  }

  if (
    params &&
    params.dashboard_type !== null &&
    params.dashboard_type !== "" &&
    params.dashboard_type !== undefined
  ) {
    getParams += `&dashboard_type=${params.dashboard_type}`;
  }
  // if (
  //   params &&
  //   params.user_id !== null &&
  //   params.user_id !== "" &&
  //   params.user_id !== undefined
  // ) {
  //   getParams += `&user_id=${params.user_id}`;
  // }
  if (
    params &&
    params.orderyear !== null &&
    params.orderyear !== "" &&
    params.orderyear !== undefined
  ) {
    getParams += `&orderyear=${params.orderyear}`;
  }

  if (
    params &&
    params.off_percentage !== null &&
    params.off_percentage !== "" &&
    params.off_percentage !== undefined
  ) {
    getParams += `&off_percentage=${params.off_percentage}`;
  }
  if (
    params &&
    params.min_off_percentage !== null &&
    params.min_off_percentage !== "" &&
    params.min_off_percentage !== undefined
  ) {
    getParams += `&min_off_percentage=${params.min_off_percentage}`;
  }
  if (
    params &&
    params.max_off_percentage !== null &&
    params.max_off_percentage !== "" &&
    params.max_off_percentage !== undefined
  ) {
    getParams += `&max_off_percentage=${params.max_off_percentage}`;
  }
  if (
    params &&
    params.rating !== null &&
    params.rating !== "" &&
    params.rating !== undefined
  ) {
    getParams += `&rating=${params.rating}`;
  }
  if (
    params &&
    params.searchuser !== null &&
    params.searchuser !== "" &&
    params.searchuser !== undefined
  ) {
    getParams += `&searchuser=${params.searchuser}`;
  }
  if (
    params &&
    params.sort_key !== null &&
    params.sort_key !== "" &&
    params.sort_key !== undefined
  ) {
    getParams += `&sort_key=${params.sort_key}`;
  }
  if (
    params &&
    params.sort_flag !== null &&
    params.sort_flag !== "" &&
    params.sort_flag !== undefined
  ) {
    getParams += `&sort_flag=${params.sort_flag}`;
  }
  if (
    params &&
    params.region !== null &&
    params.region !== "" &&
    params.region !== undefined
  ) {
    getParams += `&region=${params.region}`;
  }
  if (
    params &&
    params.organization_type !== null &&
    params.organization_type !== "" &&
    params.organization_type !== undefined
  ) {
    getParams += `&organization_type=${params.organization_type}`;
  }
  if (
    params &&
    params.inactive_status !== null &&
    params.inactive_status !== "" &&
    params.inactive_status !== undefined
  ) {
    getParams += `&inactive_status=${params.inactive_status}`;
  }

  if (
    params &&
    params.timeline &&
    params.timeline !== null &&
    params.timeline !== undefined
  ) {
    getParams += `&timeline=${params.timeline}`;
  }

  return fetch(
    `${
      service === "JSONSERVER" ? hostConfig.JSON_SERVER_URL : hostConfig.API_URL
    }${requestUrl}${getParams}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => responseStatusHandler(response))
    .then((result) =>
      result.status === 200 || result.status === 201 || result.status === 400
        ? result.json()
        : result
    )
    .catch((error) => {
      errorHandler(error);
    });
};
export const getListByApiFile = (requestUrl, params, service) => {
  let getParams = "?";
  if (
    params &&
    params.limit &&
    params.limit !== null &&
    params.limit !== undefined
  ) {
    getParams += `limit=${params.limit}`;
  }
  if (params && params.assignTo) {
    getParams += `&assignTo=${params.assignTo}`;
  }
  if (params && params.taskType) {
    getParams += `&taskType=${params.taskType}`;
  }
  if (params?.type) {
    getParams += `&type=${params.type}`;
  }
  if (params?.status) {
    getParams += `&status=${params.status}`;
  }
  if (params && params.workLocation) {
    getParams += `&workLocation=${params.workLocation}`;
  }
  if (params && params.candidateId) {
    getParams += `candidateId=${params.candidateId}`;
  }
  if (params && params.category) {
    getParams += `&category=${params.category}`;
  }
  if (params && params.details) {
    getParams += `&details=${params.details}`;
  }
  if (params && params.max_revenue) {
    getParams += `&max_revenue=${params.max_revenue}`;
  }
  if (params && params.min_revenue) {
    getParams += `&min_revenue=${params.min_revenue}`;
  }
  if (
    params &&
    params.revenue &&
    params.revenue !== null &&
    params.revenue !== undefined
  ) {
    getParams += `&revenue=${params.revenue}`;
  }

  if (params && params.return_type) {
    getParams += `&return_type=${params.return_type}`;
  }
  if (
    params &&
    params.lead_source &&
    params.lead_source !== null &&
    params.lead_source !== undefined
  ) {
    getParams += `&lead_source=${params.lead_source}`;
  }
  if (
    params &&
    params.lead_score &&
    params.lead_score !== null &&
    params.lead_score !== undefined
  ) {
    getParams += `&lead_score=${params.lead_score}`;
  }
  if (
    params &&
    params.country &&
    params.country !== null &&
    params.country !== undefined
  ) {
    getParams += `&country=${params.country}`;
  }
  if (
    params &&
    params.organization &&
    params.organization !== null &&
    params.organization !== undefined
  ) {
    getParams += `&organization=${params.organization}`;
  }
  if (
    params &&
    params.job_title &&
    params.job_title !== null &&
    params.job_title !== undefined
  ) {
    getParams += `&job_title=${params.job_title}`;
  }
  if (
    params &&
    params.industry &&
    params.industry !== null &&
    params.industry !== undefined
  ) {
    getParams += `&industry=${params.industry}`;
  }
  if (
    params &&
    params.assign_members &&
    params.assign_members !== null &&
    params.assign_members !== undefined
  ) {
    getParams += `&assign_members=${params.assign_members}`;
  }
  if (
    params &&
    params.verified_filter &&
    params.verified_filter !== null &&
    params.verified_filter !== undefined
  ) {
    getParams += `&verified_filter=${params.verified_filter}`;
  }
  if (
    params &&
    params.leads &&
    params.leads !== null &&
    params.leads !== undefined
  ) {
    getParams += `&leads=${params.leads}`;
  }
  if (
    params &&
    params.priority &&
    params.priority !== null &&
    params.priority !== undefined
  ) {
    getParams += `&priority=${params.priority}`;
  }
  if (
    params &&
    params.deal_service_type &&
    params.deal_service_type !== null &&
    params.deal_service_type !== undefined
  ) {
    getParams += `&deal_service_type=${params.deal_service_type}`;
  }
  if (
    params &&
    params.export_option &&
    params.export_option !== null &&
    params.export_option !== undefined
  ) {
    getParams += `&export_option=${params.export_option}`;
  }
  if (
    params &&
    params.from_date &&
    params.from_date !== null &&
    params.from_date !== undefined
  ) {
    getParams += `&from_date=${params.from_date}`;
  }
  if (
    params &&
    params.main_status_filter &&
    params.main_status_filter !== null &&
    params.main_status_filter !== undefined
  ) {
    getParams += `&main_status_filter=${params.main_status_filter}`;
  }
  if (
    params &&
    params.status_filter &&
    params.status_filter !== null &&
    params.status_filter !== undefined
  ) {
    getParams += `&status_filter=${params.status_filter}`;
  }
  if (
    params &&
    params.to_date &&
    params.to_date !== null &&
    params.to_date !== undefined
  ) {
    getParams += `&to_date=${params.to_date}`;
  }
  if (params && params.category_path) {
    getParams += `&category_path=${params.category_path}`;
  }
  if (params && params.company_size) {
    getParams += `&company_size=${
      params.company_size &&
      params.company_size !== null &&
      params.company_size !== undefined
    }`;
  }
  if (
    params &&
    params.is_invest &&
    params.is_invest !== null &&
    params.is_invest !== undefined
  ) {
    getParams += `&is_invest=${params.is_invest}`;
  }
  if (params && params.category_type) {
    getParams += `&category_type=${params.category_type}`;
  }
  if (params && params.price) {
    getParams += `&price=${params.price}`;
  }
  if (params && params.page) {
    getParams += `&page=${params.page}`;
  }
  if (
    params &&
    params.duration_filter &&
    params.duration_filter !== null &&
    params.duration_filter !== undefined
  ) {
    getParams += `&duration_filter=${params.duration_filter}`;
  }

  if (params && params.response_type) {
    getParams += `&response_type=${params.response_type}`;
  }

  if (
    params &&
    params.token &&
    params.token !== null &&
    params.token !== undefined
  ) {
    getParams += `token=${params.token}`;
  }

  if (
    params &&
    params.currentPage &&
    params.currentPage !== null &&
    params.currentPage !== undefined
  ) {
    getParams += `&page=${params.currentPage}`;
  }

  if (
    params &&
    params.organizationName !== null &&
    params.organizationName !== undefined
  ) {
    getParams += `&organizationName=${params.organizationName}`;
  }

  if (
    params &&
    params.sortBy &&
    params.sortBy !== null &&
    params.sortBy !== undefined
  ) {
    getParams += `&sortBy=${params.sortBy}`;
  }
  if (
    params &&
    params.category_id &&
    params.category_id !== null &&
    params.category_id !== undefined
  ) {
    getParams += `&category_id=${params.category_id}`;
  }
  if (params && params._id && params._id !== null && params._id !== undefined) {
    getParams += `&_id=${params._id}`;
  }
  if (
    params &&
    params.assign_to_filter &&
    params.assign_to_filter !== null &&
    params.assign_to_filter !== undefined
  ) {
    getParams += `&assign_to_filter=${params.assign_to_filter}`;
  }
  if (
    params &&
    params.user_id &&
    params.user_id !== null &&
    params.user_id !== undefined
  ) {
    getParams += `&user_id=${params.user_id}`;
  }
  if (
    params &&
    params.order_id &&
    params.order_id !== null &&
    params.order_id !== undefined
  ) {
    getParams += `&order_id=${params.order_id}`;
  }
  if (
    params &&
    params.product_price &&
    params.product_price !== null &&
    params.product_price !== undefined
  ) {
    getParams += `&product_price=${params.product_price}`;
  }
  if (
    params &&
    params.search &&
    params.search !== null &&
    params.search !== undefined
  ) {
    getParams += `&search=${params.search}`;
  }
  if (
    params &&
    params.isActive !== null &&
    params.isActive !== "" &&
    params.isActive !== undefined
  ) {
    getParams += `&isActive=${params.isActive}`;
  }

  if (params && params.role !== null && params.role !== undefined) {
    getParams += `&role=${params.role}`;
  }

  if (
    params &&
    params.action !== null &&
    params.action !== "" &&
    params.action !== undefined
  ) {
    getParams += `&action=${params.action}`;
  }
  if (
    params &&
    params.lastkey !== null &&
    params.lastkey !== "" &&
    params.lastkey !== undefined
  ) {
    getParams += `&lastkey=${params.lastkey}`;
  }

  if (
    params &&
    params.orderStatus !== null &&
    params.orderStatus !== "" &&
    params.orderStatus !== undefined
  ) {
    getParams += `&orderStatus=${params.orderStatus}`;
  }
  if (
    params &&
    params.productType !== null &&
    params.productType !== "" &&
    params.productType !== undefined
  ) {
    getParams += `&productType=${params.productType}`;
  }
  if (
    params &&
    params.order_status !== null &&
    params.order_status !== "" &&
    params.order_status !== undefined
  ) {
    getParams += `&order_status=${params.order_status}`;
  }

  if (
    params &&
    params.shipped_status !== null &&
    params.shipped_status !== "" &&
    params.shipped_status !== undefined
  ) {
    getParams += `&shipped_status=${params.shipped_status}`;
  }

  if (
    params &&
    params.outForDelivery_status !== null &&
    params.outForDelivery_status !== "" &&
    params.outForDelivery_status !== undefined
  ) {
    getParams += `&outForDelivery_status=${params.outForDelivery_status}`;
  }

  if (
    params &&
    params.delivered_status !== null &&
    params.delivered_status !== "" &&
    params.delivered_status !== undefined
  ) {
    getParams += `&delivered_status=${params.delivered_status}`;
  }

  if (
    params &&
    params.sort !== null &&
    params.sort !== "" &&
    params.sort !== undefined
  ) {
    getParams += `&sort=${params.sort}`;
  }
  if (
    params &&
    params.min_price !== null &&
    params.min_price !== "" &&
    params.min_price !== undefined
  ) {
    getParams += `&min_price=${params.min_price}`;
  }

  if (
    params &&
    params.max_price !== null &&
    params.max_price !== "" &&
    params.max_price !== undefined
  ) {
    getParams += `&max_price=${params.max_price}`;
  }

  // if (
  //   params &&
  //   params.category_path !== null &&
  //   params.category_path !== "" &&
  //   params.category_path !== undefined
  // ) {
  //   getParams += `&category_path=${params.category_path}`;
  // }
  if (
    params &&
    params.latest !== null &&
    params.latest !== "" &&
    params.latest !== undefined
  ) {
    getParams += `&latest=${params.latest}`;
  }
  if (
    params &&
    params.alphabetsort !== null &&
    params.alphabetsort !== "" &&
    params.alphabetsort !== undefined
  ) {
    getParams += `&alphabetsort=${params.alphabetsort}`;
  }
  if (
    params &&
    params.quantity_sort !== null &&
    params.quantity_sort !== "" &&
    params.quantity_sort !== undefined
  ) {
    getParams += `&quantity_sort=${params.quantity_sort}`;
  }
  if (
    params &&
    params.searchvalue !== null &&
    params.searchvalue !== "" &&
    params.searchvalue !== undefined
  ) {
    getParams += `&searchvalue=${params.searchvalue}`;
  }
  if (
    params &&
    params.search_value !== null &&
    params.search_value !== "" &&
    params.search_value !== undefined
  ) {
    getParams += `&search_value=${params.search_value}`;
  }
  if (
    params &&
    params.year !== null &&
    params.year !== "" &&
    params.year !== undefined
  ) {
    getParams += `&year=${params.year}`;
  }
  if (
    params &&
    params.orderyear !== null &&
    params.orderyear !== "" &&
    params.orderyear !== undefined
  ) {
    getParams += `&orderyear=${params.orderyear}`;
  }

  if (
    params &&
    params.off_percentage !== null &&
    params.off_percentage !== "" &&
    params.off_percentage !== undefined
  ) {
    getParams += `&off_percentage=${params.off_percentage}`;
  }
  if (
    params &&
    params.min_off_percentage !== null &&
    params.min_off_percentage !== "" &&
    params.min_off_percentage !== undefined
  ) {
    getParams += `&min_off_percentage=${params.min_off_percentage}`;
  }
  if (
    params &&
    params.max_off_percentage !== null &&
    params.max_off_percentage !== "" &&
    params.max_off_percentage !== undefined
  ) {
    getParams += `&max_off_percentage=${params.max_off_percentage}`;
  }
  if (
    params &&
    params.rating !== null &&
    params.rating !== "" &&
    params.rating !== undefined
  ) {
    getParams += `&rating=${params.rating}`;
  }
  if (
    params &&
    params.searchuser !== null &&
    params.searchuser !== "" &&
    params.searchuser !== undefined
  ) {
    getParams += `&searchuser=${params.searchuser}`;
  }
  if (
    params &&
    params.state !== null &&
    params.state !== "" &&
    params.state !== undefined
  ) {
    getParams += `&state=${params.state}`;
  }
  if (
    params &&
    params.country !== null &&
    params.country !== "" &&
    params.country !== undefined
  ) {
    getParams += `&country=${params.country}`;
  }
  if (
    params &&
    params.sort_key !== null &&
    params.sort_key !== "" &&
    params.sort_key !== undefined
  ) {
    getParams += `&sort_key=${params.sort_key}`;
  }
  if (
    params &&
    params.sort_flag !== null &&
    params.sort_flag !== "" &&
    params.sort_flag !== undefined
  ) {
    getParams += `&sort_flag=${params.sort_flag}`;
  }
  if (
    params &&
    params.region !== null &&
    params.region !== "" &&
    params.region !== undefined
  ) {
    getParams += `&region=${params.region}`;
  }

  return fetch(
    `${
      service === "JSONSERVER" ? hostConfig.JSON_SERVER_URL : hostConfig.API_URL
    }${requestUrl}${getParams}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => responseStatusHandler(response))
    .then((result) => result)
    .catch((error) => {
      errorHandler(error);
    });
};
/** ****************************** View Api *********************************** */
export const viewDataByApi = (requestUrl, dataId, params) => {
  
  let getParams = "?";
  if (
    params &&
    params.category_type !== null &&
    params.category_type !== "" &&
    params.category_type !== undefined
  ) {
    getParams += `category_type=${params.category_type}`;
  }
  if (
    params &&
    params.search !== null &&
    params.search !== "" &&
    params.search !== undefined
  ) {
    getParams += `&search=${params.search}`;
  }
  if (
    params &&
    params.is_deal !== null &&
    params.is_deal !== "" &&
    params.is_deal !== undefined
  ) {
    getParams += `&is_deal=${params.is_deal}`;
  }
  if (
    params &&
    params.type !== null &&
    params.type !== "" &&
    params.type !== undefined
  ) {
    getParams += `&type=${params.type}`;
  }

  if (
    params &&
    params.tab_type !== null &&
    params.tab_type !== "" &&
    params.tab_type !== undefined
  ) {
    getParams += `&tab_type=${params.tab_type}`;
  }

  if (
    params &&
    params.search_size_color !== null &&
    params.search_size_color !== "" &&
    params.search_size_color !== undefined
  ) {
    getParams += `&search_size_color=${params.search_size_color}`;
  }
  if (
    params &&
    params.variant_id !== null &&
    params.variant_id !== "" &&
    params.variant_id !== undefined
  ) {
    getParams += `&variant_id=${params.variant_id}`;
  }
  if (
    params &&
    params.limit !== null &&
    params.limit !== "" &&
    params.limit !== undefined
  ) {
    getParams += `&limit=${params.limit}`;
  }
  if (
    params &&
    params.page !== null &&
    params.page !== "" &&
    params.page !== undefined
  ) {
    getParams += `&page=${params.page}`;
  }

  if (
    params &&
    params.searchvalue !== null &&
    params.searchvalue !== "" &&
    params.searchvalue !== undefined
  ) {
    getParams += `&searchvalue=${params.searchvalue}`;
  }
  if (
    params &&
    params.source !== null &&
    params.source !== "" &&
    params.source !== undefined
  ) {
    getParams += `&source=${params.source}`;
  }
  if (
    params &&
    params.cold_reach_type &&
    params.cold_reach_type !== null &&
    params.cold_reach_type !== undefined
  ) {
    getParams += `&cold_reach_type=${params.cold_reach_type}`;
  }
  if (
    params &&
    params.user_id !== null &&
    params.user_id !== "" &&
    params.user_id !== undefined
  ) {
    getParams += `&user_id=${params.user_id}`;
  }
  if (
    params &&
    params.sort !== null &&
    params.sort !== "" &&
    params.sort !== undefined
  ) {
    getParams += `&sort=${params.sort}`;
  }
  if (
    params &&
    params.dropped !== null &&
    params.dropped !== "" &&
    params.dropped !== undefined
  ) {
    getParams += `&dropped=${params.dropped}`;
  }
  if (
    params &&
    params.mail_source !== null &&
    params.mail_source !== "" &&
    params.mail_source !== undefined
  ) {
    getParams += `&mail_source=${params.mail_source}`;
  }
  if (
    params &&
    params.activity_source !== null &&
    params.activity_source !== "" &&
    params.activity_source !== undefined
  ) {
    getParams += `&activity_source=${params.activity_source}`;
  }
  if (
    params &&
    params.meeting_source !== null &&
    params.meeting_source !== "" &&
    params.meeting_source !== undefined
  ) {
    getParams += `&meeting_source=${params.meeting_source}`;
  }
  if (
    params &&
    params.org_id &&
    params.org_id !== null &&
    params.org_id !== undefined
  ) {
    getParams += `&org_id=${params.org_id}`;
  }

  return fetch(`${hostConfig.API_URL}${requestUrl}/${dataId}${getParams}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => responseStatusHandler(response))
    .then((result) =>
      result.status === 200 || result.status === 201 || result.status === 400
        ? result.json()
        : result
    )
    .catch((error) => {
      errorHandler(error);
    });
};

/** ****************************** Update by Id Api *********************************** */
export const putDataByIdApi = (
  requestUrl,
  params,
  id,
  roleId,
  role,
  service,
  method
) => {
  let getParams = "";
  if (roleId && roleId && roleId !== null && roleId !== undefined) {
    getParams += `/${roleId}`;
  }

  if (role && role !== null && role !== "" && role !== undefined) {
    getParams += `?role=${role}`;
  }

  if (method && method !== null && method !== "" && method !== undefined) {
    getParams += `&method=${method}`;
  }
  return fetch(
    `${
      service === "JSONSERVER" ? hostConfig.JSON_SERVER_URL : hostConfig.API_URL
    }${requestUrl}/${id}${getParams}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(params),
    }
  )
    .then((response) => responseStatusHandler(response))
    .then((result) =>
      result.status === 200 || result.status === 201 || result.status === 400
        ? result.json()
        : result
    )
    .catch((error) => {
      errorHandler(error);
    });
};

/** ****************************** Update Api *********************************** */
export const putDataApi = (requestUrl, params, paramId) => {
  let getParams = "?";
  if (params?.type) {
    getParams += `&type=${params.type}`;
  }
  if (params?.status) {
    getParams += `&status=${params.status}`;
  }
  if (params?.is_deal_going) {
    getParams += `&is_deal_going=${params.is_deal_going}`;
  }
  return fetch(`${hostConfig.API_URL}${requestUrl}/${paramId}${getParams}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
    .then((response) => responseStatusHandler(response))
    .then((result) =>
      result.status === 200 || result.status === 201 || result.status === 400
        ? result.json()
        : result
    )
    .catch((error) => {
      errorHandler(error);
    });
};
export const putDataNotification = (requestUrl, params) => {
  let getParams = "?";
  if (params?.type) {
    getParams += `&type=${params.type}`;
  }
  return fetch(`${hostConfig.API_URL}${requestUrl}${getParams}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
    .then((response) => responseStatusHandler(response))
    .then((result) =>
      result.status === 200 || result.status === 201 || result.status === 400
        ? result.json()
        : result
    )
    .catch((error) => {
      errorHandler(error);
    });
};
/** ****************************** Change password Api *********************************** */
export const changePasswordDataApi = (
  requestUrl,
  params,
  id,
  service,
  changePasswordToken
) => {
  return fetch(
    `${
      service === "JSONSERVER" ? hostConfig.JSON_SERVER_URL : hostConfig.API_URL
    }${requestUrl}/${id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${changePasswordToken}`,
      },
      body: JSON.stringify(params),
    }
  )
    .then((response) => responseStatusHandler(response))
    .then((result) =>
      result.status === 200 || result.status === 201 || result.status === 400
        ? result.json()
        : result
    )
    .catch((error) => {
      errorHandler(error);
    });
};

/** ****************************** Delete by Id Api *********************************** */
export const deleteDataByIdApi = (requestUrl, dataId, service) =>
  fetch(
    `${
      service === "JSONSERVER" ? hostConfig.JSON_SERVER_URL : hostConfig.API_URL
    }${requestUrl}/${dataId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => responseStatusHandler(response))
    .then((result) =>
      result.status === 200 || result.status === 201 || result.status === 400
        ? result.json()
        : result
    )
    .catch((error) => {
      errorHandler(error);
    });

/** ****************************** Delete Api *********************************** */
export const deleteDataApi = async (requestUrl, params, service) => {
  try {
    const baseUrl =
      service === "JSONSERVER"
        ? hostConfig.JSON_SERVER_URL
        : hostConfig.API_URL;
    const fullUrl = `${baseUrl}${requestUrl}`;

    const response = await fetch(fullUrl, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`, // Ensure token is defined and valid
      },
      body: JSON.stringify(params), // Convert params to JSON string
    });

    // Check if the response is OK (status in the range 200-299)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Handle the response based on the status code
    const result = await response.json();
    if ([200, 201, 400].includes(response.status)) {
      return result;
    } else {
      return response;
    }
  } catch (error) {
    // Log the error for debugging
    console.error("Error:", error);
    errorHandler(error); // Call the error handler function
  }
};
/** ****************************** Download Api *********************************** */
export const downloadApi = (requestUrl, params) => {
  let getParams = "?";
  if (
    params &&
    params.candidateId &&
    params.candidateId !== null &&
    params.candidateId !== undefined
  ) {
    getParams += `candidateId=${params.candidateId}`;
  }
  return fetch(
    `${
      service === "JSONSERVER" ? hostConfig.JSON_SERVER_URL : hostConfig.API_URL
    }${requestUrl}${getParams}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/pdf",
      },
      responseType: "arraybuffer",
    }
  )
    .then((response) => responseStatusHandler(response))
    .then((result) =>
      result.status === 200 || result.status === 201 || result.status === 400
        ? result.json()
        : result
    )
    .catch((error) => {
      errorHandler(error);
    });
};
