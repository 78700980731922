import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import Success from "../../assets/images/success.svg";
import { DarkThemeStyled } from "../../container/Content/style";
import { FaExclamationCircle } from "react-icons/fa";
import { DarkThemeSidebar, lightTheme } from "../../constants/themes";
import "./toast.css";

const Toast = ({ success, message, showToast, setShowToast }) => {
  const isToggled = useSelector((state) => state.DarkThemeReducer.isToggled);

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        setShowToast(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showToast, setShowToast]);

  if (!showToast) {
    return null;
  }

  return (
    <ThemeProvider theme={isToggled ? DarkThemeSidebar : lightTheme}>
      <DarkThemeStyled>
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1049,
            pointerEvents: showToast ? "auto" : "none",
          }}
          onClick={() => setShowToast(false)}
        ></div>
        <div
          className="toast-container"
          style={{
            position: "fixed",
            bottom: "40px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1050,
            border: isToggled ? "1px solid #555" : "1px solid #E2E3E5",
            backgroundColor: isToggled ? "#232323" : "#FFF",
            padding: "12px 16px",
            borderRadius: "8px",
            boxShadow: "0 2px 6px rgba(0, 0, 0, 0.15)",
            pointerEvents: "auto",
            width: "100%",
            maxWidth: "420px",
          }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              {success ? (
                <img
                  src={Success}
                  alt="success"
                  style={{ marginRight: "10px" }}
                />
              ) : (
                <div>
                  <div
                    style={{
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#FFE5E5",
                      padding: "10px",
                      borderRadius: "8px",
                    }}
                  >
                    <FaExclamationCircle
                      style={{
                        color: "#800000",
                        fontSize: "20px",
                      }}
                    />
                  </div>
                </div>
              )}
              <p
                style={{
                  margin: 0,
                  marginLeft: "10px",
                  color: isToggled ? "#FFF" : "#000",
                }}
              >
                {message}
              </p>
            </div>

            <span
              onClick={(e) => {
                e.stopPropagation();
                setShowToast(false);
              }}
              style={{ cursor: "pointer" }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 5L5 15"
                  stroke={isToggled ? "#FFFFFF" : "#050E21"}
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5 5L15 15"
                  stroke={isToggled ? "#FFFFFF" : "#050E21"}
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
        </div>
      </DarkThemeStyled>
    </ThemeProvider>
  );
};

export default Toast;
