/** **************************** Import Packages ****************************** */
import React from "react";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { GlobalStyles } from "./container/Content/style";

// import {Notification} from "./components/notification/notification";
// import Preloader from "./assets/images/Preloader.gif";
// import SpinnerImg from "./assets/images/app-loader.gif";
/** **************************** Import Constants ****************************** */
import { DarkTheme, lightTheme, DealslightTheme } from "./constants/themes";
/** **************************** Import Pages ****************************** */
import Login from "./pages/Sign-in";
/** **************************** Import CSS ****************************** */
// background: url("./assets/icons/arrow-down.svg")
// no-repeat !important;
import "./App.css";

const App = () => {
  const Layout = React.lazy(() => import("./container/Layout"));
  const theme = useSelector((state) => state.DarkThemeReducer.theme);

  return (
    <ThemeProvider theme={theme === "light" ? DealslightTheme : DarkTheme}>
      <GlobalStyles />
      <Router>
        <React.Suspense
          fallback={
            <div
              className="d-grid justify-content-center align-items-center"
              style={{ height: "100vh" }}
            >
              {/* <img src={SpinnerImg} alt="" /> */}
            </div>
          }
        >
          <Switch>
            <Route exact path="/" render={() => <Login />} />
            <Route
              path="/"
              name="Home"
              render={(props) =>
                localStorage.getItem("loggedUser") ? (
                  <Layout {...props} />
                ) : (
                  (window.location.href = "/")
                )
              }
            />
            <Route path="*" exact={true} />
          </Switch>
        </React.Suspense>
      </Router>
      {/* <Notification /> */}
      <ToastContainer
        limit={1}
        autoClose={3000}
        transition={Zoom}
        pauseOnFocusLoss={false}
        newestOnTop
      />
    </ThemeProvider>
  );
};
export default App;
